import React from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import ModelVersionForm from '../../components/ModelVersionForm';

const model = {
  id: 'id',
  title: null,
  description: null,
  image: null,
  prediction_type: null,
  versions: [],
};

const ModelVersionControlCreation = () => {
  return (
    <>
      <Breadcrumbs />
      <ModelVersionForm model={model} isEdit={false} />
    </>
  );
};

export default ModelVersionControlCreation;
