import React from 'react';
import {
  CpuChipIcon,
  FingerPrintIcon,
  KeyIcon,
  RectangleStackIcon,
  CodeBracketSquareIcon,
} from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';

const Sidebar = () => {
  const { breadcrumbs } = useBreadcrumbs();

  return (
    <ul className="space-y-2 font-medium sm:mt-14" id="sidebar">
      <li
        className={clsx([
          breadcrumbs.length &&
          (breadcrumbs[0].path === '/' || breadcrumbs[0].path === '/nodes')
            ? 'bg-slate-100 rounded-sm sm:rounded-md'
            : '',
        ])}>
        <Link
          to="/"
          className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
          <CpuChipIcon className="size-6" />
          <span className="ms-3">Machines</span>
        </Link>
      </li>
      <li
        className={clsx([
          breadcrumbs.length && breadcrumbs[0].path === '/api-keys'
            ? 'bg-slate-100 rounded-sm sm:rounded-md'
            : '',
        ])}>
        <Link
          to="/api-keys"
          className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
          <KeyIcon className="size-6" />
          <span className="ms-3">API keys</span>
        </Link>
      </li>
      <li
        className={clsx([
          breadcrumbs.length && breadcrumbs[0].path === '/permissions'
            ? 'bg-slate-100 rounded-sm sm:rounded-md'
            : '',
        ])}>
        <Link
          to="/permissions"
          className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
          <FingerPrintIcon className="size-6" />
          <span className="ms-3">Permissions</span>
        </Link>
      </li>
      <li
        className={clsx([
          breadcrumbs.length &&
          breadcrumbs[0].path === '/model-version-controls'
            ? 'bg-slate-100 rounded-sm sm:rounded-md'
            : '',
        ])}>
        <Link
          to="/model-version-controls"
          className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
          <RectangleStackIcon className="size-6" />
          <span className="ms-3">Model Version Control</span>
        </Link>
      </li>
      <li
        className={clsx([
          breadcrumbs.length && breadcrumbs[0].path === '/playground'
            ? 'bg-slate-100 rounded-sm sm:rounded-md'
            : '',
        ])}>
        <Link
          to="/playground"
          className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
          <CodeBracketSquareIcon className="size-6" />
          <span className="ms-3">Playground</span>
        </Link>
      </li>
    </ul>
  );
};

export default Sidebar;
