import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

const DeploymentContext = createContext(null);

export const DeploymentProvider = ({ children }) => {
  const [openYamlEditor, setOpenYamlEditor] = useState(false);
  const [containerId, setContainerId] = useState(null);
  const yamlCodeRef = useRef(null);

  const handleContainerId = useCallback((containerId, errorEvent) => {
    setContainerId({ container_id: containerId, error_event: errorEvent });
  }, []);

  const handleOpenYamlEditor = useCallback(() => {
    setOpenYamlEditor(true);
  }, []);

  const handleCloseYamlEditor = useCallback(() => {
    setOpenYamlEditor(false);
  }, []);

  const value = useMemo(
    () => ({
      openYamlEditor,
      yamlCodeRef,
      containerId,
      handleOpenYamlEditor,
      handleCloseYamlEditor,
      handleContainerId,
    }),
    [openYamlEditor, containerId],
  );

  return (
    <DeploymentContext.Provider value={value}>
      {children}
    </DeploymentContext.Provider>
  );
};

export const useDeployment = () => useContext(DeploymentContext);
