import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getDistanceTime = (date) => {
  const now = dayjs().unix();
  const convertedDate = date.includes('Z') ? date : `${date}Z`;
  const createdDate = dayjs(`${convertedDate}`).unix();

  const distance = now - createdDate;

  if (distance <= 60) return `${distance}s ago`;

  if (distance > 60 && distance <= 3600)
    return `${Math.ceil(distance / 60)}m ago`;

  if (distance > 3600 && distance < 86400) {
    const realTimeAgo = distance / 3600;
    const hourAgo = Math.floor(realTimeAgo);
    const minuteAgo = Math.ceil(60 * (realTimeAgo - hourAgo));

    return `${hourAgo}h ${minuteAgo}m ago`;
  }

  const realTimeAgo = distance / 86400;
  const dayAgo = Math.floor(realTimeAgo);
  const hourAgo = Math.ceil(24 * (realTimeAgo - dayAgo));

  return `${dayAgo}d ${hourAgo}h ago`;
};
