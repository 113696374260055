import React from 'react';
import CodeMirror, { EditorView, EditorState } from '@uiw/react-codemirror';
import { eclipse } from '@uiw/codemirror-theme-eclipse';
import { json } from '@codemirror/lang-json';

const CodePannel = ({ defaultCode = '', onChange, disabled = false }) => {
  return (
    <CodeMirror
      value={defaultCode}
      onChange={onChange}
      className="h-[32rem] overflow-y-scroll"
      theme={eclipse}
      extensions={[
        json(),
        EditorView.lineWrapping,
        EditorState.readOnly.of(disabled),
      ]}
    />
  );
};

export default CodePannel;
