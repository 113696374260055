import { BriefcaseIcon } from '@heroicons/react/24/solid';
import React from 'react';
import Badge from '../../common/Badge';

const JobCard = ({ parentJobId = '', childrenIds = [], onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex items-center bg-white p-3 rounded-md shadow hover:bg-slate-100 cursor-pointer mb-2">
      <BriefcaseIcon className="size-4 text-slate-600 me-2.5" />
      <div className="text-slate-600">
        <div>{parentJobId}</div>
        <Badge text={`Number of children job: ${childrenIds.length}`} />
      </div>
    </div>
  );
};

export default JobCard;
