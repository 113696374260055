import React, { useCallback, useEffect, useState } from 'react';
import {
  CubeTransparentIcon,
  TrashIcon,
  CheckBadgeIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid';
import { getDistanceTime } from '../../handlers/datetimeHandler';
import { useDeployment } from '../../contexts/DeploymentContext';

const NodeContainer = ({ container, deleteContainer, findContainerId }) => {
  const [showDeleteContainer, setShowDeleteContainer] = useState(false);

  const { handleContainerId } = useDeployment();

  const openContainerLog = useCallback(
    (containerId, errorEvent) => () =>
      handleContainerId(containerId, errorEvent),
    [],
  );

  const closeDeleteContainerPopUp = useCallback(() => {
    setShowDeleteContainer(false);
  }, []);

  const confirmDeleteContainer = useCallback(() => {
    deleteContainer();
    setShowDeleteContainer(false);
  }, []);

  const openDeleteContainerPopUp = useCallback(() => {
    setShowDeleteContainer(true);
  }, []);

  const showErrorMessageEvent = useCallback((events) => {
    const errorEvent = events.find((event) => event.error.length !== 0);

    if (errorEvent)
      return <ExclamationTriangleIcon className="size-4 text-red-600" />;

    return <CheckBadgeIcon className="size-4 text-green-600" />;
  }, []);

  const getErrorMessageEvent = useCallback((events) => {
    const errorEvent = events.find((event) => event.error.length !== 0);
    return errorEvent;
  }, []);

  useEffect(() => {
    const closeDeleteContainerConfirmation = (event) => {
      if (event.keyCode !== 27) return;
      setShowDeleteContainer(false);
    };

    document.addEventListener('keydown', closeDeleteContainerConfirmation);

    return () =>
      document.removeEventListener('keydown', closeDeleteContainerConfirmation);
  }, []);

  return (
    <div className="mt-2 sm:mt-0 bg-white rounded-md shadow-sm border relative">
      <span className="text-xs p-1 bg-orange-200 text-orange-700 absolute rounded-sm right-0">
        {getDistanceTime(container.created_at)}
      </span>
      <div
        className="flex items-center p-3 cursor-pointer"
        onClick={openContainerLog(
          findContainerId(container.events, container.event_id),
          getErrorMessageEvent(container.events),
        )}>
        <CubeTransparentIcon className="size-10 text-slate-700" />
        <div className="ml-2">
          <div className="text-md text-slate-700 flex items-center">
            <div>
              {findContainerId(container.events, container.event_id).slice(
                0,
                10,
              )}
            </div>
            <div className="ml-2">
              {showErrorMessageEvent(container.events)}
            </div>
          </div>
          <div className="text-sm text slate-600">{container.image}</div>
        </div>
      </div>
      <div
        onClick={openDeleteContainerPopUp}
        className="border-t mt-1 px-3 py-3 hover:bg-slate-50 cursor-pointer">
        <div className="text-red-600 flex justify-center">
          <TrashIcon className="size-5" />
        </div>
      </div>
      {showDeleteContainer && (
        <>
          <div className="absolute top-0 left-0 w-full h-full px-3 py-4 z-20">
            <div className="text-md text-slate-600">Are you sure ?</div>
            <div className="flex items-center justify-around mt-5">
              <div
                onClick={closeDeleteContainerPopUp}
                className="cursor-pointer text-white bg-orange-600 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-orange-600 font-medium rounded-md text-sm px-5 py-2.5 text-center me-2 mb-2">
                No
              </div>
              <div
                onClick={confirmDeleteContainer}
                className="cursor-pointer border border-orange-600 hover:bg-slate-300 font-medium rounded-md text-sm px-5 py-2.5 text-center me-2 mb-2">
                Yes
              </div>
            </div>
          </div>
          <div className="bg-slate-50 w-full h-full top-0 left-0 absolute rouned-md z-10"></div>
        </>
      )}
    </div>
  );
};

export default NodeContainer;
