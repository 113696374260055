class AutoCompletionHandler {
  constructor() {
    this.nodes = [];
    this.images = [];
    this.nodeCodeMirrorOptions = [];
    this.imageVersionCodeMirrorOptions = [];
  }

  getNodeCodeMirrorOptions() {
    if (this.nodeCodeMirrorOptions.length) return this.nodeCodeMirrorOptions;

    this.nodeCodeMirrorOptions = this.nodes.map((node) => ({
      label: `@node-${node.name}`,
      type: 'text',
      apply: node.id,
      detail: node.state,
    }));

    return this.nodeCodeMirrorOptions;
  }

  getImageVersionCodeMirrorOptions() {
    if (this.imageVersionCodeMirrorOptions.length)
      return this.imageVersionCodeMirrorOptions;

    this.imageVersionCodeMirrorOptions = this.images.map((image) => ({
      label: `@image-${image.name}`,
      type: 'text',
      apply: `${image.name}:${image.version}`,
      detail: image.version,
    }));

    return this.imageVersionCodeMirrorOptions;
  }

  pushNode(node) {
    const existingNode = this.nodes.findIndex((el) => el.id === node.node_id);
    if (existingNode !== -1) return;
    this.nodes.push({
      id: node.node_id,
      name: node.node_name,
      state: node.state,
    });
  }

  pushVersion(image) {
    const existingImage = this.images.findIndex(
      (el) => el.name === image.image && el.version === image.version,
    );
    if (existingImage !== -1) return;
    this.images.push({ name: image.image, version: image.version });
  }

  removeNodeById(nodeId) {
    this.nodes = this.nodes.filter((node) => node.id !== nodeId);
  }

  removeImage(image) {
    this.images = this.images.filter(
      (img) => img.name !== image.name || img.version !== img.version,
    );
  }
}

export const autocompletionHandler = new AutoCompletionHandler();
