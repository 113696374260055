import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { wsConfig } from '../handlers/websocketHandler';
import { useAuth } from '../handlers/authHandler';

const WSContext = createContext(null);

export const WSProvider = ({ children }) => {
  const [wsLoading, setWsLoading] = useState(true);
  const wsConfigRef = useRef(wsConfig);

  const { user: token } = useAuth();

  const handleCompleteLoading = useCallback(() => {
    setWsLoading(false);
  }, []);

  useEffect(() => {
    console.log('WS Context Mount');
    wsConfigRef.current.onConnect(token, handleCompleteLoading);

    return () => {
      console.log('WS Context Unmount');
      wsConfigRef.current.dispose();
    };
  }, []);

  const mqttContextValue = useMemo(
    () => ({
      wsLoading,
    }),
    [],
  );

  return (
    <WSContext.Provider value={mqttContextValue}>{children}</WSContext.Provider>
  );
};

export const useWS = () => useContext(WSContext);
