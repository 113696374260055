import React from 'react';
import Badge from '../../common/Badge';
import { getDistanceTime } from '../../handlers/datetimeHandler';
import dayjs from 'dayjs';
import { TrashIcon } from '@heroicons/react/24/solid';

const PermissionCard = ({
  permission = {
    title: '',
    description: '',
    permission: '',
    created_at: dayjs().utc().format('YYYY-MM-DDTHH:mm:ss'),
  },
  openPermission,
  deletePermission,
}) => {
  return (
    <div className="bg-white shadow-sm relative rounded-md cursor-pointer">
      <div
        onClick={openPermission}
        className="p-3 hover:bg-gray-50 cursor-pointer">
        <div className="text-md font-medium truncate">{permission.title}</div>
        <div className="text-sm text-slate-600 truncate">
          {permission.description}
        </div>
        <div className="mt-2 flex items-center">
          <Badge text={permission.permission} />
        </div>
        <div className="text-xs text-slate-600 mt-2">
          {getDistanceTime(permission.created_at)}
        </div>
      </div>
      <div
        onClick={deletePermission}
        className="p-1 w-full flex justify-center items-center bg-orange-600 rounded-b-md py-2">
        <TrashIcon className="size-4 text-white" />
      </div>
    </div>
  );
};

export default PermissionCard;
