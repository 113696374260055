import React, { useCallback, useState } from 'react';
import { Transition } from '@headlessui/react';
import { XMarkIcon, CubeIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import NodeContainer from '../NodeContainer';
import DeploymentEditor from '../DeploymentEditor';
import { useDeployment } from '../../contexts/DeploymentContext';
import ContainerLogWrapper from '../ContainerLog/wrapper';

const ShowContainer = ({ containers, deleteContainer, findContainerId }) => {
  const [show, setShow] = useState(false);

  const { handleOpenYamlEditor } = useDeployment();

  const handleShowContainers = useCallback(() => {
    setShow(!show);
  }, [show]);

  const closeShowContainerPopup = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <>
      <div
        onClick={handleShowContainers}
        className="sm:hidden z-40 fixed bottom-0 left-0 font-medium text-white rounded-sm w-full text-center text-sm p-3 bg-orange-600 hover:bg-orange-700">
        Show containers
      </div>
      {show && (
        <div className="sm:hidden bg-slate-500 opacity-50 absolute w-full top-0 left-0 h-full z-20"></div>
      )}
      <Transition show={show}>
        <div
          className={clsx([
            // Base styles
            'absolute sm:hidden bottom-10 left-0 z-30 transition ease-in-out h-3/4 px-3 py-4 overflow-y-auto bg-white w-full',
            // Shared closed styles
            'data-[closed]:opacity-0',
            // Entering styles
            'data-[enter]:duration-200 data-[enter]:data-[closed]:translate-y-full',
            // Leaving styles
            'data-[leave]:duration-300 data-[leave]:data-[closed]:translate-y-full',
          ])}>
          <div className="relative">
            <div className="absolute right-0" onClick={closeShowContainerPopup}>
              <XMarkIcon className="size-5 text-slate-500 cursor-pointer" />
            </div>
            <div className="text-lg font-medium border-b text-slate-500 flex items-center justify-between pb-2">
              <div className="flex items-center">
                <CubeIcon className="size-6" />
                <div className="ml-2">Containers</div>
              </div>

              <div
                onClick={handleOpenYamlEditor}
                className="bg-orange-600 text-white text-sm rounded-md px-3 py-2 text-center cursor-pointer hover:bg-orange-700 shadow-sm mr-8">
                Deploy
              </div>
            </div>

            <div className="h-[32rem] overflow-y-scroll">
              {containers.map((container) => (
                <NodeContainer
                  key={container.id}
                  container={container}
                  deleteContainer={deleteContainer(
                    container.id,
                    findContainerId(container.events, container.event_id),
                  )}
                  findContainerId={findContainerId}
                />
              ))}
            </div>
          </div>
          <DeploymentEditor />
          <ContainerLogWrapper />
        </div>
      </Transition>
    </>
  );
};

export default ShowContainer;
