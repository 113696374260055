import qs from 'qs';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const HOST =
  process.env.NODE_ENV == 'production'
    ? '/api/v1'
    : 'http://192.168.102.102:8000/api/v1';

const unauthenInstance = axios.create({
  baseURL: HOST,
});

export const authenInstance = axios.create({
  baseURL: HOST,
});

export const login = async (username, password) => {
  try {
    const token = await unauthenInstance.post(
      '/me/login',
      qs.stringify({ username, password }),
    );

    authenInstance.defaults.headers.common['Authorization'] =
      `Bearer ${token.data.access_token}`;
    localStorage.setItem('digime_access_token', token.data.access_token);

    return {
      token: token.data.access_token,
      expired_time: jwtDecode(token.data.access_token)['exp'],
    };
  } catch (error) {
    return false;
  }
};

export const getMQTTCredential = async () => {
  const mqttCredentials = await authenInstance.get('/mqtt/credentials');
  return mqttCredentials.data;
};

export const getNodeStates = async () => {
  const nodeStates = await authenInstance.get('/nodes/states');
  return nodeStates.data;
};

export const getStatesByNodeId = async (nodeId) => {
  const nodeStates = await authenInstance.get(`/nodes/${nodeId}/states`);
  return nodeStates.data.data;
};

export const getContainersByNodeId = async (nodeId) => {
  const containers = await authenInstance.get(`/nodes/${nodeId}/containers`);
  return containers.data.data;
};

export const getResourcesByContainerId = async (nodeId, containerId) => {
  const resources = await authenInstance.get(
    `/nodes/${nodeId}/containers/${containerId}/used_resources`,
  );
  return resources.data;
};

export const getAllNodes = async () => {
  const nodes = await authenInstance.get('/nodes');
  return nodes.data;
};

export const deployAContainer = async ({ image, node_id, command_run }) => {
  const container = await authenInstance.post('/docker/containers/deploy', {
    image,
    command_run,
    node_id,
  });
  return container.data;
};

export const removeAContainer = async ({ containerId, nodeId }) => {
  const result = await authenInstance.post(
    `/nodes/${nodeId}/docker/containers/${containerId}/remove`,
  );
  return result;
};

export const getAllRegistries = async () => {
  const result = await authenInstance.get(`/registries`);
  return result;
};

export const getAllImageByRegistryId = async (registryId) => {
  const result = await authenInstance.get(
    `/registries/${registryId}/docker/images`,
  );
  return result;
};

export const deployContainerByYamlFile = async (yamlCode) => {
  const result = await authenInstance.post(`/docker/containers/yaml/deploy`, {
    yaml_payload: yamlCode,
  });
  return result.data;
};

export const getAllApiKeys = async (offset = 0, limit = 20) => {
  const result = await authenInstance.get(
    `/api-keys?offset=${offset}&limit=${limit}`,
  );
  return result.data.data;
};

export const getApiKeyById = async (apiKeyId) => {
  const result = await authenInstance.get(`/api-keys/${apiKeyId}`);
  return result.data.data;
};

export const getAllPermissions = async (offset = 0, limit = 100) => {
  const result = await authenInstance.get(
    `/permissions?offset=${offset}&limit=${limit}`,
  );
  return result.data.data;
};

export const getAllModelVersionControls = async (offset = 0, limit = 100) => {
  const result = await authenInstance.get(
    `/model-version-controls?offset=${offset}&limit=${limit}`,
  );
  return result.data.data;
};

export const updateApiKeyByIdentifier = async (apiKeyId, body) => {
  const result = await authenInstance.put(`/api-keys/${apiKeyId}`, body);
  return result.data.data;
};

export const createApiKey = async (body) => {
  const result = await authenInstance.post(`/api-keys`, body);
  return result.data.data;
};

export const deleteApiKeyByIdentifier = async (apiKeyId) => {
  const result = await authenInstance.delete(`/api-keys/${apiKeyId}`);
  return result.data.data;
};

export const updatePermissionByIdentifier = async (permissionId, body) => {
  const result = await authenInstance.patch(
    `/permissions/${permissionId}`,
    body,
  );
  return result.data.data;
};

export const createPermission = async (body) => {
  const result = await authenInstance.post(`/permissions`, body);
  return result.data.data;
};

export const deletePermissionByIdentifier = async (permissionId) => {
  const result = await authenInstance.delete(`/permissions/${permissionId}`);
  return result.data.data;
};

export const createModelVersionControl = async (body) => {
  const result = await authenInstance.post(`/model-version-controls`, body);
  return result.data.data;
};

export const getModelVersionControlByIdentifier = async (
  modelVersionControlId,
) => {
  const result = await authenInstance.get(
    `/model-version-controls/${modelVersionControlId}`,
  );
  return result.data.data;
};

export const updateModelVersionControlByIdentifier = async (
  modelVersionControlId,
  body,
) => {
  const result = await authenInstance.put(
    `/model-version-controls/${modelVersionControlId}`,
    body,
  );
  return result.data.data;
};

export const deleteModelVersionControlByIdentifier = async (
  modelVersionControlId,
) => {
  const result = await authenInstance.delete(
    `/model-version-controls/${modelVersionControlId}`,
  );
  return result.data.data;
};

export const deleteNodeByIdentifier = async (nodeId) => {
  const result = await authenInstance.delete(`/nodes/${nodeId}`);
  return result.data.data;
};

export const getNodeById = async (nodeId) => {
  const result = await authenInstance.get(`/nodes/${nodeId}`);
  return result.data.data;
};

export const makeRequest = async (url, method, payload) => {
  const result = await unauthenInstance({
    method,
    url,
    data: JSON.parse(payload),
  });
  return result.data;
};

export const getJobByIdWithApiKey = async (jobId, apiKey) => {
  const result = await unauthenInstance.get(
    `/ml/prediction/jobs/${jobId}?api_key=${apiKey}`,
  );
  return result.data;
};

export const interactContainer = async (nodeId, containerId, payload) => {
  const result = await authenInstance.post(
    `/nodes/${nodeId}/containers/${containerId}/interact`,
    payload,
  );
  return result.data;
};
