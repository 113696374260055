import React from 'react';
import {
  XCircleIcon,
  CheckCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import { useToast } from '../../contexts/ToastContext';

const Toast = () => {
  const { toast, disableToast } = useToast();

  return (
    <Transition
      className={clsx([
        // Base styles
        'z-50 border transition ease-in-out overflow-y-auto bg-white dark:bg-gray-800',
        // Shared closed styles
        'data-[closed]:opacity-0',
        // Entering styles
        'data-[enter]:duration-200 data-[enter]:data-[closed]:translate-x-full',
        // Leaving styles
        'data-[leave]:duration-300 data-[leave]:data-[closed]:translate-x-full',
      ])}
      show={toast.open}>
      <div className="flex items-center max-w-xs p-2 text-gray-500 bg-white rounded-md shadow fixed top-2 right-2">
        <div
          className={clsx([
            'inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-md',
            toast?.type === 'SUCCESS' && 'text-blue-500 bg-blue-100',
            toast?.type === 'ERROR' && 'text-red-500 bg-red-100',
          ])}>
          {toast?.type === 'ERROR' ? (
            <XCircleIcon className="size-3" />
          ) : (
            <CheckCircleIcon className="size-3" />
          )}
        </div>
        <div className="ms-3 text-sm font-normal">{toast.content}</div>
        <button
          onClick={disableToast}
          type="button"
          className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8">
          <XMarkIcon className="size-4" />
        </button>
      </div>
    </Transition>
  );
};

export default Toast;
