import React, { useEffect } from 'react';
import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import PermissionForm from './form';

const PermissionFormTransition = ({
  permission = {
    title: '',
    description: '',
    permission: '',
    created_at: dayjs().utc().format('YYYY-MM-DDTHH:mm:ss'),
  },
  open = false,
  appendPermission,
  close,
}) => {
  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-y-hidden');
      return;
    }

    document.body.classList.remove('overflow-y-hidden');
  }, [open]);

  useEffect(() => {
    const turnOffPopoverByEscKey = (e) => {
      if (e.keyCode === 27) close();
    };

    document.addEventListener('keydown', turnOffPopoverByEscKey);

    return () =>
      document.removeEventListener('keydown', turnOffPopoverByEscKey);
  }, []);

  return (
    <Transition show={open}>
      <div
        className={clsx([
          // Base styles
          'absolute top-0 left-0 z-20 transition ease-in-out h-3/4 overflow-y-auto w-full h-full',
          // Shared closed styles
          'data-[closed]:opacity-0',
          // Entering styles
          'data-[enter]:duration-200',
          // Leaving styles
          'data-[leave]:duration-300',
        ])}>
        <div className="fixed sm:absolute top-0 left-0 w-full z-30 flex items-center justify-center h-full">
          <PermissionForm
            close={close}
            permission={permission}
            appendPermission={appendPermission}
          />
        </div>
        <div className="bg-slate-500 opacity-50 w-full h-screen sm:h-full z-20 fixed sm:absolute top-0 left-0"></div>
      </div>
    </Transition>
  );
};

export default PermissionFormTransition;
