import { nanoid } from 'nanoid';
import { Subject } from 'rxjs';

const HOST =
  process.env.NODE_ENV == 'production' ? '' : 'http://192.168.102.102:8000';

class WebSocketConfiguration {
  constructor(host) {
    this._host = host;
    this._isLogin = false;
    this._containerLogSubj = new Subject();
  }

  getContainerLogSubj() {
    return this._containerLogSubj;
  }

  handleMessage = (event) => {
    const message = JSON.parse(event.data);
    if (message.reason === 'Authenticated successfully') {
      this._isLogin = true;
      return;
    }

    if (!this._isLogin) {
      console.log('You are not authenticated');
      return;
    }

    this._containerLogSubj.next(message);
  };

  handleError(event) {
    console.error(event);
    this._isLogin = false;
  }

  login(token) {
    this._websocket.send(
      JSON.stringify({
        auth_cmd: token,
      }),
    );
  }

  sendMessage(message) {
    this._websocket.send(JSON.stringify(message));
  }

  onConnect(token, callback) {
    console.log('Connect to websocket');
    this._websocket = new WebSocket(`${this._host}/api/v1/ws/${nanoid(20)}`);
    this._websocket.addEventListener('open', () => {
      console.log('Websocket is open');
      this.login(token);
      callback();
      this.onMessage();
    });
  }

  onMessage() {
    this._websocket.addEventListener('message', this.handleMessage);
  }

  onError() {
    this._websocket.addEventListener('error', this.handleError);
  }

  dispose() {
    this._websocket.close();
    this._websocket.removeEventListener('message', this.handleMessage);
    this._websocket.removeEventListener('error', this.handleError);
    this._websocket.removeEventListener('open', this.onConnect);
  }
}

export const wsConfig = new WebSocketConfiguration(HOST);
