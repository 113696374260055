import './App.css';
import React from 'react';
import { AuthProvider } from './handlers/authHandler';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Routes, Route } from 'react-router-dom';
import Error from './pages/Error';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import NodeList from './pages/NodeList';
import NodeDetail from './pages/NodeDetail';
import { DeploymentProvider } from './contexts/DeploymentContext';
import ApiKey from './pages/ApiKey';
import ApiKeyDetail from './pages/ApiKeyDetail';
import ApiKeyCreation from './pages/ApiKeyCreation';
import Permission from './pages/Permission';
import ModelVersionControl from './pages/ModelVersionControl';
import ModelVersionControlCreation from './pages/ModelVersionControlCreation';
import ModelVersionControlDetail from './pages/ModelVersionControlDetail';
import Playground from './pages/Playground';

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }>
          <Route path="/" element={<NodeList />} />
          <Route path="nodes" element={<NodeList />} />
          <Route
            path="nodes/:nodeId"
            element={
              <DeploymentProvider>
                <NodeDetail />
              </DeploymentProvider>
            }
          />
          <Route path="api-keys" element={<ApiKey />} />
          <Route path="api-keys/:apiKeyId" element={<ApiKeyDetail />} />
          <Route path="api-keys/create" element={<ApiKeyCreation />} />
          <Route path="permissions" element={<Permission />} />
          <Route
            path="model-version-controls"
            element={<ModelVersionControl />}
          />
          <Route
            path="model-version-controls/:modelVersionId"
            element={<ModelVersionControlDetail />}
          />
          <Route
            path="model-version-controls/create"
            element={<ModelVersionControlCreation />}
          />
          <Route path="playground" element={<Playground />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
