import React, { useMemo } from 'react';
import { HomeIcon, SlashIcon } from '@heroicons/react/24/solid';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';
import { Link } from 'react-router-dom';

const Breadcrumbs = () => {
  const { breadcrumbs } = useBreadcrumbs();

  const breadcrumbLength = useMemo(() => breadcrumbs.length, [breadcrumbs]);

  return (
    <div className="mt-5 flex items-center">
      <HomeIcon className="size-4" />
      {breadcrumbs.map((item, index) => {
        if (index !== 0 && index === breadcrumbLength - 1)
          return (
            <div key={index + 1} className="text-sm flex items-center">
              <SlashIcon className="size-4" />
              <div>{item.name}</div>
            </div>
          );

        return (
          <Link key={index + 1} to={item.path}>
            <div className="text-sm flex items-center text-slate-600">
              <SlashIcon className="size-4" />
              <div>{item.name}</div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
