import React, { useCallback, useRef, useState, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { ArrowPathIcon } from '@heroicons/react/24/solid';

import Badge from '../../common/Badge';
import MultipleSelect from '../../common/MultipleSelect';
import {
  createApiKey,
  getAllModelVersionControls,
  getAllPermissions,
  updateApiKeyByIdentifier,
} from '../../handlers/apiCallHandler';
import { useAuth } from '../../handlers/authHandler';
import { useToast } from '../../contexts/ToastContext';
import WebhookConfig from '../WebhookConfig';

const ApiKeyForm = ({
  is_edit,
  apiKey = {
    title: '',
    description: '',
    permissions: [],
    tags: [],
    is_banned: false,
    api_key: '',
    whitelist_ml_model_version: [],
    webhook_config: {},
  },
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [allPermissions, setAllPermissions] = useState([]);
  const [allModelVersions, setAllModelVersions] = useState([]);
  const [isBanned, setIsBanned] = useState(false);

  const [tags, setTags] = useState([]);

  const navigate = useNavigate();

  const params = useParams();

  const auth = useAuth();

  const { openToast } = useToast();

  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const permissionsRef = useRef([]);
  const whitelistMLModelVersionsRef = useRef([]);
  const tagRef = useRef(null);
  const apiKeyRef = useRef(null);
  const webhookConfigRef = useRef(apiKey.webhook_config);

  const appendPermission = useCallback((value) => {
    permissionsRef.current = permissionsRef.current.concat(value);
  }, []);

  const removePermission = useCallback((value) => {
    permissionsRef.current = permissionsRef.current.filter(
      (perm) => perm.value !== value.value,
    );
  }, []);

  const appendModelVersion = useCallback((value) => {
    whitelistMLModelVersionsRef.current =
      whitelistMLModelVersionsRef.current.concat(value);
  }, []);

  const removeModelVersion = useCallback((value) => {
    whitelistMLModelVersionsRef.current =
      whitelistMLModelVersionsRef.current.filter(
        (perm) => perm.value !== value.value,
      );
  }, []);

  const appendTag = useCallback(
    (event) => {
      if (event.keyCode === 13) {
        const convertedValue = event.target.value?.replaceAll(/-/gm, '_');
        setTags(tags.concat(convertedValue));
        tagRef.current.value = '';
        return;
      }
    },
    [tags],
  );

  const removeTag = useCallback(
    (value) => () => {
      setTags((state) => state.filter((tag) => tag !== value));
    },
    [],
  );

  const handleChangeIsBannedToggle = useCallback(() => {
    setIsBanned((state) => !state);
  }, []);

  const saveApiKey = useCallback(() => {
    setIsSubmitting(true);

    const body = {
      title: titleRef.current.value,
      description: descriptionRef.current.value,
      permissions: permissionsRef.current.map((item) => item.value),
      api_key: apiKeyRef.current.value,
      whitelist_ml_model_version: whitelistMLModelVersionsRef.current.map(
        (item) => ({
          prediction_type: item.value.split('|')[0],
          version: item.value.split('|')[1],
        }),
      ),
      is_banned: isBanned,
      tags: tags,
      webhook_config: webhookConfigRef.current,
    };

    if (is_edit) {
      updateApiKeyByIdentifier(params['apiKeyId'], body)
        .then(() => {
          setTimeout(() => setIsSubmitting(false), 500);
        })
        .catch((err) => {
          setTimeout(() => setIsSubmitting(false), 500);
          if (err.response.status === 401) {
            return auth.logout();
          }
        });

      return;
    }

    createApiKey(body)
      .then(() => {
        setTimeout(() => {
          setIsSubmitting(false);
          navigate('/api-keys');
        }, 500);
      })
      .catch((err) => {
        setTimeout(() => {
          setIsSubmitting(false);

          if (err?.response?.status === 422)
            openToast('ERROR', 'Field is required');
          else openToast('ERROR', err.response.data.message);

          if (err?.response?.status === 401) {
            return auth.logout();
          }
        }, 500);
      });
  }, [tags, isBanned]);

  useEffect(() => {
    titleRef.current.value = apiKey.title;
    descriptionRef.current.value = apiKey.description;
    permissionsRef.current = apiKey.permissions.map((item) => ({
      value: item,
    }));
    whitelistMLModelVersionsRef.current = apiKey.whitelist_ml_model_version.map(
      (item) => ({ value: `${item.prediction_type}|${item.version}` }),
    );
    tagRef.current.value = '';
    apiKeyRef.current.value = apiKey.api_key;
    webhookConfigRef.current = apiKey.webhook_config;

    setTags(apiKey.tags);
    setIsBanned(apiKey.is_banned);
  }, [apiKey]);

  useEffect(() => {
    // Get all permissions
    getAllPermissions()
      .then((data) =>
        setAllPermissions(
          data.data.map((item) => ({
            id: item.id,
            title: item.title,
            value: item.permission,
          })),
        ),
      )
      .catch((err) => {
        if (err.response.status === 401) {
          return auth.logout();
        }
      });

    // Get all model version controls
    getAllModelVersionControls()
      .then((data) => {
        const converted = data.data.flatMap((item) =>
          item.versions.map((version, index) => ({
            version,
            id: `${item.id}_${index}`,
            title: `${item.title} <div class="text-sm text-color-slate-100">Version: <span class="bg-orange-100 text-orange-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">${version}</span></div>`,
            description: item.description,
            value: `${item.prediction_type}|${version}`,
          })),
        );

        setAllModelVersions(converted);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          return auth.logout();
        }
      });
  }, []);

  return (
    <div className="mt-3 bg-white rounded-md shadow-sm p-3">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 mb-3">
        <div>
          <label
            htmlFor="title"
            className="block mb-2 text-sm font-medium text-gray-900">
            Title
          </label>
          <input
            ref={titleRef}
            id="title"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-orange-600 focus:border-orange-600 block w-full p-2.5"
            placeholder="Enter title"
            required
          />
        </div>
        <div>
          <label
            htmlFor="description"
            className="block mb-2 text-sm font-medium text-gray-900">
            Description
          </label>
          <input
            ref={descriptionRef}
            id="description"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-orange-600 focus:border-orange-600 block w-full p-2.5"
            placeholder="Enter description"
            required
          />
        </div>
        <div className="relative">
          <label
            htmlFor="permission"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Permissions
          </label>
          <MultipleSelect
            list={allPermissions}
            selected={permissionsRef.current}
            text="Select permissions"
            onSelect={appendPermission}
            onDeselect={removePermission}
          />
        </div>
        <div>
          <label
            htmlFor="tags"
            className="block mb-2 text-sm font-medium text-gray-900">
            Tags
          </label>
          <div className="flex items-center p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md overflow-x-flow">
            <input
              onKeyDown={appendTag}
              ref={tagRef}
              id="tags"
              className="focus:border-none focus:outline-none bg-gray-50 w-20"
              placeholder="Enter tags"
            />
            <div className="overflow-x-scroll flex items-center w-full">
              {tags.map((tag, index) => (
                <Badge
                  key={index}
                  onClick={removeTag(tag)}
                  isTruncate={false}
                  text={tag}
                />
              ))}
            </div>
          </div>
        </div>
        <div>
          <label
            htmlFor="api-key"
            className="block mb-2 text-sm font-medium text-gray-900">
            Api key
          </label>
          <input
            ref={apiKeyRef}
            disabled={is_edit}
            id="api-key"
            className={clsx([
              'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-orange-600 focus:border-orange-600 block w-full p-2.5',
              is_edit && 'cursor-not-allowed bg-gray-100',
            ])}
            placeholder="Enter api key"
            required
          />
        </div>
        <div>
          <label
            htmlFor="whitelist-model"
            className="block mb-2 text-sm font-medium text-gray-900">
            Whitelist model version
          </label>
          <MultipleSelect
            list={allModelVersions}
            selected={whitelistMLModelVersionsRef.current}
            text="Select model version"
            onSelect={appendModelVersion}
            onDeselect={removeModelVersion}
          />
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={isBanned}
              onChange={handleChangeIsBannedToggle}
            />
            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-orange-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-600"></div>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
              Is banned
            </span>
          </label>
        </div>
      </div>
      <WebhookConfig webhookRef={webhookConfigRef} />
      <div className="grid grid-cols-3 mt-3">
        <div></div>
        <div
          onClick={saveApiKey}
          className="p-2 bg-orange-600 text-center text-white rounded-md font-medium hover:bg-orange-700 cursor-pointer">
          {is_edit ? (
            <div className="flex items-center justify-center">
              {isSubmitting && (
                <ArrowPathIcon className="size-4 animate-spin" />
              )}
              <div className="ml-2">Save</div>
            </div>
          ) : (
            <div className="flex items-center justify-center">
              {isSubmitting && (
                <ArrowPathIcon className="size-4 animate-spin" />
              )}
              <div className="ml-2">Create</div>
            </div>
          )}
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default ApiKeyForm;
