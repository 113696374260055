import React from 'react';
import { Transition } from '@headlessui/react';

import ContainerLog from '.';
import { useDeployment } from '../../contexts/DeploymentContext';
import clsx from 'clsx';

const ContainerLogWrapper = () => {
  const { containerId } = useDeployment();

  return (
    <Transition show={containerId?.container_id ? true : false}>
      <div
        className={clsx([
          // Base styles
          'absolute top-0 left-0 z-40 sm:z-30 transition ease-in-out h-3/4 overflow-y-auto w-full h-full bg-slate-500/50',
          // Shared closed styles
          'data-[closed]:opacity-0',
          // Entering styles
          'data-[enter]:duration-200',
          // Leaving styles
          'data-[leave]:duration-300',
        ])}>
        <ContainerLog />
      </div>
    </Transition>
  );
};

export default ContainerLogWrapper;
