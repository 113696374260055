import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid';

import Breadcrumbs from '../../components/Breadcrumbs';
import ApiKeyCard from '../../components/ApiKeyCard';
import {
  deleteApiKeyByIdentifier,
  getAllApiKeys,
} from '../../handlers/apiCallHandler';
import { useAuth } from '../../handlers/authHandler';

const ApiKey = () => {
  const [apiKeys, setApiKeys] = useState([]);

  const auth = useAuth();

  useEffect(() => {
    getAllApiKeys()
      .then((data) => {
        setApiKeys(data.api_keys);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          return auth.logout();
        }
      });
  }, []);

  const removeApiKey = useCallback(
    (apiKeyId) => () => {
      setApiKeys((apiKeys) =>
        apiKeys.filter((apiKey) => apiKey.id !== apiKeyId),
      );

      deleteApiKeyByIdentifier(apiKeyId)
        .then(() => {})
        .catch((err) => {
          if (err?.response?.status === 401) return auth.logout();
        });
    },
    [],
  );

  return (
    <>
      <Breadcrumbs />
      <Link to={`/api-keys/create`}>
        <div className="bg-orange-600 text-white p-2 text-center font-medium w-full rounded-md mt-2 hover:bg-orange-700 block sm:hidden shadow-sm">
          Create
        </div>
      </Link>
      <div className="mt-3 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-2">
        <Link to={`/api-keys/create`}>
          <div className="items-center justify-center border-2 border-slate-400 border-dashed rounded-md cursor-pointer min-h-28 hidden sm:flex">
            <PlusCircleIcon className="size-7 text-slate-400" />
          </div>
        </Link>
        {apiKeys.map((apiKey) => (
          <div className="relative" key={apiKey.id}>
            <div
              onClick={removeApiKey(apiKey.id)}
              className="absolute top-0 right-0 p-2 bg-red-600 text-white rounded-bl-md rounded-tr-md cursor-pointer hover:shadow-md">
              <TrashIcon className="size-4" />
            </div>
            <Link to={`/api-keys/${apiKey.id}`}>
              <ApiKeyCard apiKey={apiKey} />
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default ApiKey;
