import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import ModelVersionForm from '../../components/ModelVersionForm';
import { getModelVersionControlByIdentifier } from '../../handlers/apiCallHandler';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../handlers/authHandler';

const ModelVersionControlDetail = () => {
  const [modelVersionControl, setModelVersionControl] = useState({
    id: '',
    title: '',
    description: '',
    image: '',
    prediction_type: '',
    versions: [],
  });

  const params = useParams();

  const auth = useAuth();

  useEffect(() => {
    getModelVersionControlByIdentifier(params['modelVersionId'])
      .then((data) => {
        setModelVersionControl(data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) return auth.logout();
      });
  }, []);

  return (
    <>
      <Breadcrumbs />
      <ModelVersionForm model={modelVersionControl} isEdit={true} />
    </>
  );
};

export default ModelVersionControlDetail;
