/* eslint-disable */

import React, { useState, useEffect, useCallback } from 'react';
import {
  CpuChipIcon,
  CubeTransparentIcon,
  ComputerDesktopIcon,
  ArrowTrendingUpIcon,
  BoltIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';

import { useWS } from '../../contexts/WebSocketContext';

const NodeState = ({ node }) => {
  const [nodeState, setNodeState] = useState({
    card_temperature:
      node?.states?.length == 0 ? 'nil' : node?.states[0]?.card_temperature,
    card_wattage_usage:
      node?.states?.length == 0 ? 'nil' : node?.states[0]?.card_wattage_usage,
    cpu_usage: node?.states?.length == 'nil' ? 0 : node?.states[0]?.cpu_usage,
    memory_usage:
      node?.states?.length == 0 ? 'nil' : node?.states[0]?.memory_usage,
  });

  const { wsSubjectRef } = useWS();

  const getClassNameByNodeHealth = useCallback((nodeState) => {
    if (nodeState === 'healthy') return 'bg-green-100 text-green-800';

    if (nodeState === 'unhealthy') return 'bg-yellow-100 text-yellow-800';

    if (nodeState === 'unready') return 'bg-slate-100 text-slate-800';

    return 'bg-red-100 text-red-800';
  }, []);

  const getHealthStateText = useCallback((nodeState) => {
    if (nodeState === 'healthy') return 'Healthy';

    if (nodeState === 'unhealthy') return 'Unhealthy';

    if (nodeState === 'unready') return 'Unready';

    return 'Died';
  }, []);

  const updateState = useCallback((data) => {
    if (node.state === 'died') return;

    if (data.node_id === node.node_id) {
      setNodeState({
        card_temperature: data.state.card_temperature,
        card_wattage_usage: data.state.card_wattage_usage,
        cpu_usage: data.state.cpu_usage,
        memory_usage: data.state.memory_usage,
      });
    }
  }, []);

  useEffect(() => {
    const subscription = wsSubjectRef?.current?.subscribe({
      next: (message) => {
        updateState(message);
      },
    });

    return () => subscription?.unsubscribe();
  }, []);

  return (
    <div className="bg-white rounded-md shadow-sm p-3 hover:hover:bg-gray-50 cursor-pointer">
      <span
        className={clsx([
          'text-xs font-medium me-2 px-2.5 py-0.5 rounded',
          getClassNameByNodeHealth(node.state),
        ])}>
        {getHealthStateText(node.state)}
      </span>
      <div className="flex items-center mt-2">
        <CubeTransparentIcon className="size-6" />
        <div className="font-medium text-md ml-2 truncate">
          {node.node_name}
        </div>
      </div>
      <div className="text-xs sm:text-sm mt-2 flex items-center text-slate-600">
        <CpuChipIcon className="size-5" />
        <div className="ml-2 truncate">{node.card_name}</div>
      </div>
      <div className="text-xs sm:text-sm flex items-center text-slate-600 mt-1">
        <ComputerDesktopIcon className="size-5" />
        <div className="ml-2 truncate">{node.operation_system}</div>
      </div>
      <div className="border-t mt-2"></div>
      <div className="mt-2 flex items-center justify-around">
        <div className="flex items-center text-slate-600">
          <ArrowTrendingUpIcon className="size-4" />
          <div className="ml-2 text-sm font-medium">
            {nodeState.card_temperature} °C
          </div>
        </div>
        <div className="flex items-center text-slate-600">
          <BoltIcon className="size-4" />
          <div className="ml-2 text-sm font-medium">
            {nodeState.card_wattage_usage} W
          </div>
        </div>
      </div>
    </div>
  );
};

export default NodeState;
