import React from 'react';
import ApiKeyForm from '../../components/ApiKeyForm';
import Breadcrumbs from '../../components/Breadcrumbs';

const apiKey = {
  title: '',
  description: '',
  permissions: [],
  tags: [],
  is_banned: false,
  api_key: '',
  whitelist_ml_model_version: [],
};

const ApiKeyCreation = () => {
  return (
    <>
      <Breadcrumbs />
      <ApiKeyForm apiKey={apiKey} is_edit={false} />
    </>
  );
};

export default ApiKeyCreation;
