import { useState } from 'react';
import { authenInstance } from '../handlers/apiCallHandler';
import { jwtDecode } from 'jwt-decode';

export const useLocalStorage = (accessTokenKey) => {
  const accessToken = localStorage.getItem(accessTokenKey);
  const [user, setUser] = useState(() => {
    try {
      const decodeToken = jwtDecode(accessToken);

      if (decodeToken.exp < Date.now() / 1000) return null;

      return {
        username: 'Digime',
        token: accessToken,
        expired_time: jwtDecode(accessToken)?.exp,
      };
    } catch {
      return null;
    }
  });

  authenInstance.defaults.headers['Authorization'] = `Bearer ${accessToken}`;

  return [user, setUser];
};
