import React from 'react';
import clsx from 'clsx';

const Badge = ({ text, isTruncate = true, customClassName = '', ...props }) => {
  return (
    <span
      {...props}
      className={clsx([
        'bg-orange-100 text-orange-800 font-medium px-2.5 py-0.5 rounded',
        isTruncate && 'truncate',
        customClassName.length ? customClassName : 'text-xs',
      ])}>
      {text}
    </span>
  );
};

export default Badge;
