import React from 'react';

import { Navigate } from 'react-router-dom';
import { useAuth } from '../../handlers/authHandler';

export const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user || parseInt(user.expired_time) < Date.now() / 1000) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};
