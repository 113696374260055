import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

const ToastContext = createContext(null);

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState({
    type: '',
    content: '',
    timeout: 5,
    open: false,
  });

  const timeoutIdRef = useRef(undefined);

  const openToast = useCallback((type, content) => {
    clearTimeout(timeoutIdRef.current);
    setToast((state) => ({ ...state, type, content, open: true }));
    timeoutIdRef.current = setTimeout(() => {
      disableToast();
    }, 2000);
  }, []);

  const enableToast = useCallback(() => {
    setToast((state) => ({ ...state, open: true }));
  }, []);

  const disableToast = useCallback(() => {
    setToast((state) => ({ ...state, open: false }));
  }, []);

  const value = useMemo(
    () => ({ toast, openToast, enableToast, disableToast }),
    [toast],
  );

  return (
    <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
