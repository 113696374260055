import React from 'react';
import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import JobResponseEditor from '.';

const JobResponseWrapper = ({
  open = false,
  job = {},
  closeJobResultPopUp,
  apiKey = [],
}) => {
  return (
    <Transition show={open}>
      <div
        className={clsx([
          // Base styles
          'absolute top-0 left-0 z-40 sm:z-30 transition ease-in-out overflow-y-auto w-full h-full bg-slate-500/50',
          // Shared closed styles
          'data-[closed]:opacity-0',
          // Entering styles
          'data-[enter]:duration-200',
          // Leaving styles
          'data-[leave]:duration-300',
        ])}>
        <JobResponseEditor
          job={job}
          closeJobResultPopUp={closeJobResultPopUp}
          apiKey={apiKey}
        />
      </div>
    </Transition>
  );
};

export default JobResponseWrapper;
