import React from 'react';
import Chart from 'react-apexcharts';

const LineChart = ({ xaxis = [], series = [], series_name = '' }) => {
  const chartOption = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        id: 'basic-bar',
      },
      xaxis: {
        categories: xaxis,
        type: 'datetime',
        labels: {
          datetimeUTC: false,
          rotate: 0,
          datetimeFormatter: {
            year: 'yyyy',
            month: "MMM 'yy",
            day: 'dd MMM',
            hour: 'HH:mm',
          },
        },
      },
      stroke: {
        curve: 'smooth',
        width: 1,
      },
    },
    series: [
      {
        name: series_name,
        data: series,
      },
    ],
  };

  return (
    <Chart
      type="line"
      options={chartOption.options}
      series={chartOption.series}
    />
  );
};

export default LineChart;
