import { BriefcaseIcon, XMarkIcon } from '@heroicons/react/24/solid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import MultipleSelect from '../../common/MultipleSelect';
import CodePannel from '../CodePannel';
import { getJobByIdWithApiKey } from '../../handlers/apiCallHandler';

const JobResponseEditor = ({ job, closeJobResultPopUp, apiKey }) => {
  const [loading, setLoading] = useState(true);
  const jobRef = useRef([job?.childrenJobIds?.[0]]);

  const [jobResp, setJobResp] = useState('');

  const handleSelectJob = useCallback((value) => {
    jobRef.current = [value];
    getJobByIdWithApiKey(jobRef.current[0]['id'], apiKey['value']).then(
      (data) => setJobResp(JSON.stringify(data, null, 2)),
    );
  }, []);

  const handleDeselectJob = useCallback(() => {
    jobRef.current = [];
  });

  useEffect(() => {
    jobRef.current = [
      {
        id: job?.childrenJobIds?.[0],
        title: job?.childrenJobIds?.[0],
        value: job?.childrenJobIds?.[0],
      },
    ];
    setLoading(false);
  }, []);

  useEffect(() => {
    console.log(apiKey);
    getJobByIdWithApiKey(jobRef.current[0]['id'], apiKey['value'])
      .then((data) => setJobResp(JSON.stringify(data, null, 2)))
      .catch((err) => console.log(err));
  }, [job['jobParentId']]);

  return (
    <div className="flex items-center justify-center h-full">
      <div className="text-md text-slate-600 bg-white p-2 rounded-md bg-white p-4 w-full sm:w-3/5">
        <div className="flex items-center">
          <div className="flex items-center">
            <BriefcaseIcon className="size-5 me-2.5" />
            <div className="font-medium">
              Job parent id: {job['parentJobId']}
            </div>
          </div>
          <button
            onClick={closeJobResultPopUp}
            className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8">
            <XMarkIcon className="size-4 text-slate-600 cursor-pointer" />
          </button>
        </div>
        <div>{loading}</div>
        <div className="mt-3">
          <MultipleSelect
            list={job['childrenJobIds']?.map((el) => ({
              id: el,
              title: el,
              value: el,
            }))}
            selected={jobRef.current}
            onSelect={handleSelectJob}
            onDeselect={handleDeselectJob}
            text={'Select job id'}
            isMutileSelect={false}
          />
          <CodePannel defaultCode={jobResp} disabled={true} />
        </div>
      </div>
    </div>
  );
};

export default JobResponseEditor;
