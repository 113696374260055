import React from 'react';
import Badge from '../../common/Badge';
import { getDistanceTime } from '../../handlers/datetimeHandler';
import dayjs from 'dayjs';

const ApiKeyCard = ({
  apiKey = {
    title: 'Title',
    description: 'Description',
    permissions: ['example'],
    created_at: dayjs().utc().format('YYYY-MM-DDTHH:mm:ss'),
  },
}) => {
  return (
    <div className="bg-white p-3 rounded-md shadow-sm hover:bg-gray-50 cursor-pointer">
      <div className="text-md font-medium truncate">{apiKey.title}</div>
      <div className="text-sm text-slate-600 truncate">
        {apiKey.description}
      </div>
      <div className="mt-2 flex items-center">
        <Badge text={apiKey.permissions?.[0]} />
        <span className="text-xs text-slate-600">+10</span>
      </div>
      <div className="text-xs text-slate-600 mt-2">
        {getDistanceTime(apiKey.created_at)}
      </div>
    </div>
  );
};

export default ApiKeyCard;
