import React, { useCallback, useEffect, useRef, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import MultipleSelect from '../../common/MultipleSelect';
import { getAllApiKeys, makeRequest } from '../../handlers/apiCallHandler';
import { useAuth } from '../../handlers/authHandler';
import CodePannel from '../../components/CodePannel';
import clsx from 'clsx';
import Badge from '../../common/Badge';
import JobCard from '../../components/JobCard';
import JobResponseWrapper from '../../components/JobResponseEditor/wrapper';

const defaultPrefixUrl = '{{host}}/api/v1/ml/urls/predict?api_key={api_key}';

const defaultPayload = `{
  "type": "image",
  "data": [
    "https://www.mrlinhadventure.com/UserFiles/image/Travel-blog/Image-content/Shopping-in-Vietnam1.jpg"
  ],
  "prediction_type": "OBJECT_DETECTION_PREDICTION",
  "version": "1.0.3"
}
`;

const statusCode = {
  400: '400 Bad Request',
  500: 'Internal Error Server',
  403: '403 Forbidden',
};

const Playground = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const apiKeyRef = useRef([]);

  const [url, setUrl] = useState(defaultPrefixUrl);

  const [jsonRequest, setJsonRequest] = useState(defaultPayload);
  const [jsonResponse, setJsonResponse] = useState({
    response: '',
    className: 'hidden',
    statusCodeValue: '',
  });
  const [isSending, setIsSending] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobResult, setJobResult] = useState({
    parentJobId: null,
    childrenJobIds: null,
  });

  const auth = useAuth();

  useEffect(() => {
    getAllApiKeys()
      .then((data) =>
        setApiKeys(
          data['api_keys'].map((el) => ({
            id: el.id,
            title: el.title,
            value: el.api_key,
          })),
        ),
      )
      .catch((err) => {
        if (err?.response?.status == 401) {
          return auth.logout();
        }
      });
  }, []);

  const handleSelectApiKey = useCallback((value) => {
    apiKeyRef.current = [value];
    setUrl(
      defaultPrefixUrl.replaceAll(/\{api_key\}/g, apiKeyRef.current[0].value),
    );
  }, []);

  const handleDeselectApiKey = useCallback(() => {
    apiKeyRef.current = [];
    setUrl(defaultPrefixUrl);
  }, []);

  const handleChangeRequestBody = useCallback((value) => {
    setJsonRequest(value);
  }, []);

  const handleSendHttpRequest = useCallback(() => {
    if (url.includes('{api_key}') || isSending) return;

    setIsSending(true);

    const finalUrl = url.replace('{{host}}/api/v1', '');

    makeRequest(finalUrl, 'post', jsonRequest)
      .then((data) => {
        setJsonResponse({
          response: JSON.stringify(data, null, 2),
          className: 'text-green-700 bg-green-400',
          statusCodeValue: '200 OK',
        });
        setIsSending(false);
        setJobs((jobs) => (data['data'] ? jobs.concat(data['data']) : jobs));
      })
      .catch((err) => {
        setJsonResponse({
          response: JSON.stringify(err?.response?.data, null, 2),
          className: '',
          statusCodeValue: statusCode[err?.response?.status],
        });
        setIsSending(false);
      });
  }, [jsonRequest, url]);

  const openJobResult = useCallback(
    ({ parentJobId, childrenJobIds }) =>
      () => {
        setJobResult({
          parentJobId,
          childrenJobIds,
        });
      },
    [],
  );

  const closeJobResult = useCallback(() => {
    setJobResult({ parentJobId: null, childrenJobIds: null });
  }, []);

  return (
    <>
      <Breadcrumbs />
      <div className="grid grid-cols-4 gap-2">
        <div className="mt-3">
          <MultipleSelect
            list={apiKeys}
            selected={apiKeyRef.current}
            onSelect={handleSelectApiKey}
            onDeselect={handleDeselectApiKey}
            text="Select API Key"
            isMutileSelect={false}
          />
        </div>
      </div>
      <div className="mt-3 grid grid-cols-12 gap-3">
        <div className="col-span-11 border rounded-md flex items-center">
          <div className="p-2 font-medium text-yellow-500 border-r">POST</div>
          <input
            className="w-full p-2 rounded-r cursor-not-allowed"
            value={url}
            disabled
          />
        </div>
        <div
          onClick={handleSendHttpRequest}
          className={clsx([
            'p-2 text-center rounded-md text-white font-medium',
            !url.includes('{api_key}') && !isSending
              ? 'bg-orange-600 hover:bg-orange-700 cursor-pointer'
              : 'bg-gray-500 cursor-not-allowed',
          ])}>
          {!isSending ? 'Send' : 'Sending'}
        </div>
      </div>
      <div className="mt-3 grid grid-cols-4 gap-1">
        <div className="col-span-3 grid grid-cols-2 gap-2">
          <div className="border rounded-md bg-white">
            <div className="p-2 border-b">
              <div className="text-sm text-slate-600 font-medium">
                Request body
              </div>
            </div>
            <CodePannel
              defaultCode={jsonRequest}
              onChange={handleChangeRequestBody}
            />
          </div>
          <div className="border rounded-md bg-white">
            <div className="flex items-center justify-between p-2 border-b">
              <div className="text-sm text-slate-600 font-medium">
                Response body
              </div>
              <Badge
                text={jsonResponse.statusCodeValue}
                customClassName={jsonResponse.className}
              />
            </div>
            <CodePannel disabled={true} defaultCode={jsonResponse.response} />
          </div>
        </div>
        <div className="border rounded-md">
          <div className="border-b bg-white p-2 text-sm font-medium text-slate-600 rounded-t">
            Job
          </div>
          <div className="h-[32rem] overflow-y-scroll">
            {jobs.reverse().map((job) => (
              <JobCard
                parentJobId={job['parent_job_id']}
                childrenIds={job['job_ids']}
                onClick={openJobResult({
                  parentJobId: job['parent_job_id'],
                  childrenJobIds: job['job_ids'],
                })}
              />
            ))}
          </div>
        </div>
      </div>
      <JobResponseWrapper
        open={jobResult.parentJobId}
        job={jobResult}
        apiKey={apiKeyRef.current?.[0]}
        closeJobResultPopUp={closeJobResult}
      />
    </>
  );
};

export default Playground;
