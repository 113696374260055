import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import Badge from '../../common/Badge';
import { getDistanceTime } from '../../handlers/datetimeHandler';

const ModelVersionCard = ({
  model = {
    title: '',
    description: '',
    versions: [],
    prediction_type: '',
    created_at: dayjs().utc().format('YYYY-MM-DDTHH:mm:ss'),
  },
}) => {
  const getExtendNumberVersion = useCallback((versions) => {
    if (versions.length == 1) return '';

    return `+${versions.length - 1}`;
  }, []);

  return (
    <div className="bg-white p-3 rounded-md shadow-sm hover:bg-gray-50 cursor-pointer">
      <div className="text-md font-medium truncate">{model.title}</div>
      <div className="text-sm text-slate-600 truncate">{model.description}</div>
      <div className="mt-2 flex items-center">
        <Badge text={model.versions?.[0]} />
        <span className="text-xs text-slate-600">
          {getExtendNumberVersion(model.versions)}
        </span>
      </div>
      <div className="text-xs text-slate-600 mt-2">
        {getDistanceTime(model.created_at)}
      </div>
    </div>
  );
};

export default ModelVersionCard;
